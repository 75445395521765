<template>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
      <b-card title="Bantuan">
        <b-card-text>Pertanyaan dan Konsultasi terkait HRIS-DJPb dapat Anda ajukan melalui form dibawah ini.</b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
